const speed = 300;
const timeoutDurationInt = 50;
const timeoutDurationFloat = 10;

function animateText(counters){

    counters.forEach( counter => {
        const animate = () => {
           const baseValue = +counter.getAttribute('akhi');
           const value = +counter.getAttribute('akhi');

           let dataValue = counter.innerText;
           if(document.documentElement.lang == "pl"){
                dataValue = parseFloat(dataValue.replace(',','.')); 
            }

            const data = +dataValue;
            let sign = counter.dataset.sign;
           let time = value / 300;

           if(Number.isInteger(baseValue) && baseValue.toString().indexOf(".") == -1) {
            time = Math.round((value / speed)+ 0.5);
           }
          if(data < value) {
            

            if (baseValue < 10 && baseValue.toString().indexOf(".") == -1){
                time = Math.round((value / 11900)+ 0.5);
            }

              let inputValue = Math.round((data + time) * 100) / 100;


              if(document.documentElement.lang == "pl"){
                inputValue = inputValue.toString().replace('.',',');
                }
                
              
               counter.innerText = inputValue;


               if(baseValue.toString().indexOf(".") == -1) {
                if (baseValue < 10){
                    setTimeout(animate, 350);

                }else if(baseValue < 100){
                    setTimeout(animate, 150);
                }else{
                    setTimeout(animate, timeoutDurationInt);
                }
               } else {
                if(baseValue < 2){
                
                    setTimeout(animate, 60);
                }else{
                    setTimeout(animate, timeoutDurationFloat);
                }
               }
               
             }else{
                if(document.documentElement.lang == "pl"){
                    let finalValue;
                    if (value == 2.01){
                        // finalValue.round(1);
                        counter.setAttribute('akhi', 2);
                    
                        finalValue = 2;
                        finalValue = finalValue.toString().replace('.',',');
                    }else if (value == 4.01){
                        counter.setAttribute('akhi', 4);
                        finalValue = 4;

                        finalValue = finalValue.toString().replace('.',',');
                    }else{
                        finalValue = value.toString().replace('.',',');
                    }

                    finalValue = finalValue.replace(/(?=(?:.{3})*$)/g, ' ');
                    counter.innerText = finalValue + sign;
                }else{
                    let finalValue;
                    if (value == 2.01){
                        // finalValue.round(1);
                     
                        finalValue = 2;
                        counter.innerText = finalValue + sign;
                    }else if (value == 4.01){
                        finalValue = 4;
                        counter.innerText = finalValue + sign;
                        
                    }else{
                        counter.innerText = value + sign;
                    }
                   
                }
             }
        }
        animate();
     });
     
}


function resetAnimation(counters){
    counters.forEach( counter => {
        if(counter.getAttribute('akhi') == 2){
            counter.setAttribute("akhi", 2.01);
        }
        if(counter.getAttribute('akhi') == 4){
            counter.setAttribute("akhi", 4.01);
        }
        counter.innerText = 0;
    });
}


const positionIndicator1 = document.querySelectorAll('.slide-in');

function addZeroBetween(counters){
    counters.forEach( counter => {
        counter.innerText = counter.innerText.replace(/\d(?=(?:\d{3})+(?!\d))/g, "$& ");
        
    });
}

function getPosition() {
    let st = window.pageYOffset;
    positionIndicator1.forEach(element => {
    
            let imageTop = element.getBoundingClientRect().top + st;


            let counters = element.querySelectorAll('.counter--number');

            if (window.innerWidth > 500){
                
            if (st > (imageTop - window.innerHeight * 0.8)) {
                animateText(counters);
                addZeroBetween(counters);
            }else{
                resetAnimation(counters);
            }
        }else{
            if (st > (imageTop - window.innerHeight * 0.65)) {
                animateText(counters);
                addZeroBetween(counters);
            }else{
                resetAnimation(counters);
            }
        }
        
    })
}

let endValues = document.querySelectorAll('.counter--number');

window.addEventListener('scroll', async () => {
    const result = await getPosition();
    addZeroBetween(endValues);
}); 

window.onload = getPosition();
window.onload =  addZeroBetween(endValues);
const scrolledFromTop = window.scrollY;
